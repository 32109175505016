<template>
  <Subheader v-if="loggedIn && isActive === 't1'" />
  <div class="container" v-if="!loggedIn">
    <div class="row mt-5 pt-5">
      <div class="col-12 not-loggedin">
        <h1 class="font-primary success-heading text-center w-100">You need to sign in</h1>
        <p class="auth-explanation-text">In order to proceed to this page, you will need to sign in with your BLSO account. If you don't have an account yet then you can register via the button indicated below.</p>
        <router-link to="/login" class="button">Sign In</router-link>
        <router-link to="/register" class="mt-4">Register</router-link>
      </div>
    </div>
  </div>
  
  <div 
    v-else
    :class="!hasCompletedKyc || !hasDeposits ? 'mt-5' : ''"
    class="layout mt-5"
  >
    <div :class="!userData?.completed_kyc ? 'offset-top' : ''" class="container content pb-5 pt-1">
      <div class="row">
        <div class="tabs-wrapper">
          <div
            :class="isActive === 't1' ? 'active' : ''"
            @click="selectTab('t1')"
            class="tab"
          >
            <span>KYC</span>
          </div>
          <div
            :class="isActive === 't2' ? 'active' : ''"
            @click="selectTab('t2')"
            class="tab"
          >
            <span>Financial</span>
          </div>
          <div
            :class="isActive === 't3' ? 'active' : ''"
            @click="selectTab('t3')"
            class="tab"
          >
            <span>Security</span>
          </div>
        </div>
        <div>
          <div v-if="kycLoading">
            <div class="loader"></div>
          </div>
          <div v-else class="col-lg-12">
            <div v-if="isActive === 't1' && !showKycForm && hasCompletedKyc !== 1">
              <h3 class="mb-4 text-center mt-5">You need to complete KYC</h3>
              <p class="kyc-info-text">
                Nunc, tempus egestas fames cursus tincidunt pulvinar fermentum mi ultrices diam sollicitudin condimentum integer ultrices diam suspendisse rhoncus. Fames cursus tincidunt pulvinar fermentum mi ultrices.
              </p>
              <button style="margin: 0 auto;" @click="handleKycBtnClick" class="button">Start with KYC</button>
            </div>
            <div
              v-if="hasDeposits"
              :class="'tabContent' + (isActive == 't1' ? 'active' : '')"
            >
              <div v-if="!iframeID && !user">
                <div class="loader"></div>
              </div>
              <!--  :src="`https://go.idnow.de/ten31/identifications/${iframeID}/identification/start`" -->
              <div v-if="iframeID">
                <iframe
                  :src="`https://go.test.idnow.de/${iframeID}`"
                  style="width: 100vw; height: 800px"
                  allow="camera ; microphone "
                  allowfullscreen=""
                  class="kyc-iframe mt-5"
                ></iframe>
              </div>
              <div
                class="
                  ps-0
                  pe-0 pe-lg-5
                  ps-lg-5
                  ms-0 ms-lg-4
                  py-4
                  mt-4
                  kyc-form-wrapper
                "
                v-else-if="hasCompletedKyc || showKycForm"
              >
                <h3 class="mb-4 text-center">Your KYC information</h3>
                <p v-if="user.is_corporate" class="kyc-info-text">
                  You will be receiving email regarding the Corporate KYC from Ten31 team shortly.
                </p>
                <p v-else class="kyc-info-text">
                  Please provide all the required KYC information below, this is a legal requirement for participation.
                </p>
                <div class="errors-wrapper d-flex flex-column align-items-center justify-content-center mb-5">
                  <p class="validation-error" :key="error" v-for="error in kycErrors">{{error[0]}}</p>
                </div>
                <div v-if="iframeID"></div>
                <div class="row" v-if="!user.is_corporate">
                  <div class="form-group col-md-6">
                    <label>First name*</label>
                    <input
                      placeholder="Enter your first name"
                      :disabled="hasCompletedKyc"
                      class="customForm"
                      type="text"
                      name="name"
                      v-model="kycForm.firstname"
                    />
                    <p
                      class="validation-error"
                      v-if="!kycForm.firstname && validationCalled"
                    >
                      Please enter your first name
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Last name*</label>
                    <input
                      placeholder="Enter your last name"
                      :disabled="hasCompletedKyc"
                      class="customForm"
                      type="text"
                      name="last_name"
                      v-model="kycForm.lastname"
                    />
                    <p
                      class="validation-error"
                      v-if="!kycForm.lastname && validationCalled"
                    >
                      Please enter your last name
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Email*</label>
                    <input
                      placeholder="Enter your email"
                      :disabled="true"
                      class="customForm"
                      type="text"
                      name="last_name"
                      v-model="kycForm.email"
                    />
                    <!-- <p class="validation-error" v-if="!kycForm.email && validationCalled">Please enter your email</p> -->
                  </div>
                  <div class="form-group col-md-6">
                    <label>Birthplace*</label>
                    <input
                      placeholder="Enter your birthplace"
                      :disabled="hasCompletedKyc"
                      class="customForm"
                      type="text"
                      name="last_name"
                      v-model="kycForm.birthplace"
                    />
                    <p
                      class="validation-error"
                      v-if="!kycForm.birthplace && validationCalled"
                    >
                      Please enter your birthplace
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Address*</label>
                    <input
                      placeholder="Enter your address"
                      :disabled="hasCompletedKyc"
                      class="customForm"
                      type="text"
                      name="address"
                      v-model="kycForm.street"
                    />
                    <p
                      class="validation-error"
                      v-if="!kycForm.street && validationCalled"
                    >
                      Please enter your address
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Gender*</label>
                    <select :disabled="hasCompletedKyc" class="w-100" v-model="kycForm.gender">
                      <option selected class="d-none" value="">
                        Select your gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Diverse</option>
                    </select>
                    <p
                      class="validation-error"
                      v-if="!kycForm.gender && validationCalled"
                    >
                      Please enter your gender
                    </p>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>City*</label>
                      <input
                        placeholder="Enter your city"
                        :disabled="hasCompletedKyc"
                        class="customForm"
                        type="text"
                        name="city"
                        v-model="kycForm.city"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.birthplace && validationCalled"
                      >
                        Please enter your city
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Country*</label>
                      <select
                        :disabled="hasCompletedKyc"
                        id="country_id"
                        v-model="kycForm.country"
                        class="customForm"
                      >
                        <option class="d-none" selected>Select Country</option>
                        <template
                          v-for="(country, key) in countries"
                          v-bind:key="key"
                        >
                          <option :value="key">{{ country }}</option>
                        </template>
                      </select>
                      <p
                        class="validation-error"
                        v-if="!kycForm.country && validationCalled"
                      >
                        Please enter your country
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Nationality*</label>
                      <select
                        placeholder="Enter your nationality"
                        :disabled="hasCompletedKyc"
                        id="country_id"
                        v-model="kycForm.nationality"
                        class="customForm"
                      >
                        <option class="d-none" selected>Select Country</option>
                        <template
                          v-for="(nationality, key) in nationalities"
                          v-bind:key="key"
                        >
                          <option :value="key">{{ nationality }}</option>
                        </template>
                      </select>
                      <p
                        class="validation-error"
                        v-if="!kycForm.nationality && validationCalled"
                      >
                        Please enter your nationality
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Postal code*</label>
                      <input
                        placeholder="Enter your postal code"
                        :disabled="hasCompletedKyc"
                        class="customForm"
                        type="text"
                        name="post_code"
                        v-model="kycForm.zipcode"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.zipcode && validationCalled"
                      >
                        Please enter your postal code
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Birthday*</label>
                      <datepicker
                        :class="hasCompletedKyc ? 'disabled' : ''"
                        class="datepicker-input mt-2 w-100"
                        v-model="kycForm.birthday"
                        startingView="year"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.birthday && validationCalled"
                      >
                        Please enter your birthday
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Mobile phone number*</label>
                      <input
                        placeholder="Enter your mobile phone"
                        :disabled="hasCompletedKyc"
                        class="customForm"
                        type="text"
                        v-model="kycForm.mobilephone"
                      />
                      <p
                        class="validation-error"
                        v-if="!kycForm.mobilephone && validationCalled"
                      >
                        Please enter your mobile phone
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row"></div>
                <div class="form-group mt-4 d-flex justify-content-center pt-2" v-if="!user.is_corporate">
                  <button
                    v-if="!hasCompletedKyc"
                    type="button"
                    class="button w-100"
                    @click="submitKyc"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div
              class="py-4 mt-5"
              v-else-if="hasDeposits === false && isActive === 't1'"
            >
              <h3 class="mb-4 text-center">Minimum deposit not made</h3>
              <p class="kyc-no-deposits text-center">
                You haven't deposited the minimum amount required to complete KYC yet. <br> Please click
                <router-link to="/investments">here</router-link> to go to the wallet page
                and make a deposit
              </p>
            </div>
            <div class="col-lg-9" v-else-if="user && user.status === 3">
              DEACTIVATED
            </div>
            <div class="col-lg-9" v-else-if="user && user.status === 4">
              Country entered during registration does not match country given
              during KYC process
            </div>
            <div :class="'tabContent' + (isActive == 't2' ? 'active' : '')">
              <div v-if="bankInfoLoading">
                <div class="loader"></div>
              </div>
              <form v-else class="financial-form py-4">
                <h3 class="mt-4 mb-3 text-center">Bank details</h3>
                <p class="mb-4 text-center disclaimer">
                  Please indicate your bank details below.
                </p>
                <p class="mt-4 text-center disclaimer">
                  This account will also be used in the future for any payouts including dividends.
                </p>
                <div class="form-group mt-5">
                  <label>IBAN*</label>
                  <input
                    v-model="bankForm.iban"
                    class="customForm"
                    type="text"
                    placeholder="IBAN"
                  />
                  <p
                    class="validation-error"
                    v-if="!bankForm.iban && bankValidationCalled"
                  >
                    Please enter your IBAN
                  </p>
                  <p class="validation-error" v-if="invalidIban">
                    Please enter a valid IBAN
                  </p>
                </div>
                  
                <div class="form-group">
                  <div class="form-group position-relative">
                    <label>BIC*</label>
                    <input
                      @keydown="e => handleBicKeyPress(e)"
                      @input="input => handleBicInput(input)"
                      v-model="bankForm.bic_code"
                      class="customForm"
                      type="text"
                      placeholder="BIC"
                    />
                    <div v-if="bicList.length" class="bic-list">
                      <div :class="highlightedBic === index ? 'active' : ''" @click="handleBicSelect(bic)" :key="bic" v-for="(bic, index) of bicList">
                        {{bic}}
                      </div>
                    </div>
                  </div>
                  <p
                    class="validation-error"
                    v-if="!bankForm.bic_code && bankValidationCalled"
                  >
                    Please enter your BIC
                  </p>
                  <p class="validation-error" v-if="invalidBic && bankForm.bic_code.length > 0">
                    Please enter a valid BIC
                  </p>
                </div>
                <!-- <h3 class="mt-4 mb-3 text-center mt-5 mb-4">Wallet address</h3>
                <div class="form-group mb-4">
                  <label>Receiving address*</label>
                  <input
                    v-model="bankForm.receiving_address"
                    class="customForm"
                    type="text"
                    placeholder="Receiving address"
                  />
                  <p
                    class="validation-error"
                    v-if="!bankForm.receiving_address && bankValidationCalled"
                  >
                    Please enter your receiving address
                  </p>
                  <p class="validation-error" v-if="invalidEthAddress">Ethereum address is not valid</p>
                </div> -->

                <div class="form-group mt-4 pt-2">
                  <div
                    class="d-flex flex-column flex-lg-row align-items-center"
                  >
                    <button
                      :disabled="disableBankSubmit"
                      type="button"
                      @click="updateBankInfo"
                      class="button w-100"
                    >
                      Update your bank details
                    </button>
                    <!-- <div class="ms-0 ms-md-5 mt-4 mt-lg-0"><button class="button link blueDark">SEPA Lastschriftmandat <span class="chevronRight right"></span></button></div> -->
                  </div>
                  <!-- <p class="mt-3 bank-success" v-if="bankUpdateSuccess">Bank information updated successfully</p> -->
                </div>
              </form>
            </div>

            <div :class="'tabContent' + (isActive == 't3' ? 'active' : '')">
              <form @submit.prevent action="#">
                <div class="py-4 password-form">
                  <h3 class="mt-4 text-center">Change password</h3>
                  <p class="my-4 text-center disclaimer">
                    Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number and one special character.
                  </p>
                  <div class="form-group">
                    <label>Current password</label>
                    <input
                      autocomplete="off"
                      class="customForm"
                      type="password"
                      name="current_password"
                      placeholder="Enter your current password here..."
                      v-model="current_password"
                    />
                  </div>
                  <div class="form-group position-relative">
                    <label>New password</label>
                    <input
                      autocomplete="off"
                      class="customForm"
                      type="password"
                      name="password"
                      placeholder="Enter your new password here..."
                      v-model="password"
                    />
                    <p v-if="passwordShort" class="validation-error">Password must be at least 8 characters long.</p>
                    <p v-if="invalidPassword" class="validation-error">Password does not meet requirements.</p>
                    <div tabindex="-1" class="info-wrapper">
                      <div class="info-icon">
                          i
                      </div>
                      <div class="info-box">
                          <p class="mb-0 pe-0">Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number and one special character.</p>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Confirm new password</label>
                    <input
                      autocomplete="off"
                      class="customForm"
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirm your new password here..."
                      v-model="password_confirmation"
                    />
                    <p v-if="passwordConfirmNoMatch" class="validation-error">Password confirmation does not match new password</p>
                  </div>

                  <div class="form-group mt-4 pt-2">
                    <button @click="changePassword" class="button w-100">
                      Change password
                    </button>
                    <button @click="logout" class="button white w-100 mt-3">
                      Logout
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    @click="closeModal"
    :class="showDepositModal ? 'active' : ''"
    class="modal-wrapper"
  >
    <div :class="showDepositModal ? 'active' : ''" @click.stop v-if="showDepositModal" class="deposit-modal">
      <div @click="closeModal" class="close-modal">✕</div>
      <h3 class="text-center">Initial Deposit</h3>
      <p class="text-center">
        Please make a Initial deposit of 100 euros to be able to start with KYC procedure.
      </p>
      <router-link class="button w-100" to="/investments">Make an Initial Deposit</router-link>
    </div>
  </div>

</template>

<script lang="js">
import { defineComponent } from "vue";
import config from "../../config";
import Datepicker from "vue3-datepicker";
import Subheader from "../../components/Subheader.vue";
import iban from 'iban';
import { ethers } from 'ethers';

export default defineComponent({
  name: "Settings",
  data: function () {
    return {
      nationalities: [],
      highlightedBic: null,
      bicList: [],
      disableBankSubmit: false,
      invalidBic: null,
      invalidEthAddress: false,
      passwordConfirmNoMatch: false,
      kycErrors: null,
      kycLoading: true,
      isActive: "t1",
      user: null,
      accessToken: null,
      showLoginModal: false,
      password: null,
      password_confirmation: null,
      iframeID: null,
      bankInfoLoading: true,
      bankUpdateSuccess: false,
      validationCalled: false,
      invalidPassword: false,
      passwordShort: false,
      invalidIban: false,
      bankValidationCalled: false,
      hasDeposits: null,
      current_password: null,
      hasCompletedKyc: null,
      showKycForm: true,
      profileData: null,
      loggedIn: null,
      showDepositModal: false,
      userData: null,
      firstLoad: true,
      bankForm: {
        // bank_name: "",
        // receiving_address: "",
        iban: "",
        bic_code: "",
      },
      kycForm: {
        firstname: "",
        lastname: "",
        email: "",
        birthday: new Date(),
        birthplace: "",
        gender: "",
        city: "",
        country: "",
        nationality: "",
        street: "",
        zipcode: "",
        mobilephone: "",
      },
    };
  },
  components: {
    // Header,
    // Footer
    // Autocomplete,
    Subheader,
    Datepicker,
  },
  methods: {
    handleBicKeyPress(e) {
      if (!this.bicList.length) {
        return;
      }
      if (e.keyCode === 40) {
        if (this.highlightedBic === null || this.highlightedBic + 1 === this.bicList.length) {
          this.highlightedBic = 0;
        } else if (typeof this.highlightedBic === 'number' && this.highlightedBic + 1 < this.bicList.length) {
          this.highlightedBic++;
        }
      } else if (e.keyCode === 38) {
        if (this.highlightedBic === null || this.highlightedBic === 0) {
          this.highlightedBic = this.bicList.length - 1;
        } else if (typeof this.highlightedBic === 'number' && this.highlightedBic > 0) {
          this.highlightedBic--;
        }
      } else if (e.keyCode === 13) {
        this.handleBicSelect(this.bicList[this.highlightedBic]);
      }
    },
    handleBicSelect(value) {
      this.bicList = [];
      this.bankForm.bic_code = value;
    },
    handleBicInput(e) {
      if (e.target.value.length < 2) {
        return;
      }
      this.getBicList(e.target.value);
    },
    getBicList(input) {
      fetch(`${config.API}/api/v1/bic-suggestions`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bic: input
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data.error || data.errors) {
          this.$toast.error(data.error);
          return;
        }
        const bicArray = [];
        for (const item in data.data.bic_suggestions) {
          bicArray.push(item);
        }
        this.bicList = bicArray.slice(0, 9);
      })
      .catch(error => {
        console.log(error);
        this.$toast.error(error);
      });
    },
    handleKycBtnClick() {
      if (this.hasDeposits) {
        this.showKycForm = true;
      } else {
        this.showDepositModal = true;
      }
    },
    async getUserProfile() {
      try {
        fetch(`${config.API}/api/v1/get-profile`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
              Accept: "application/json",
          }
        })
        .then(res => res.json())
        .then(data => {
          if (data.data.personal_profile) {
            this.kycForm.firstname = data.data.personal_profile.name;
            this.kycForm.lastname = data.data.personal_profile.last_name;
            this.kycForm.birthday = new Date(data.data.personal_profile.birthday);
            this.kycForm.birthplace = data.data.personal_profile.birthplace;
            this.kycForm.gender = data.data.personal_profile.gender ? data.data.personal_profile.gender.toLowerCase() : null;
            this.kycForm.city = data.data.personal_profile.city;
            this.kycForm.country = data.data.personal_profile.country_id;
            this.kycForm.nationality = data.data.personal_profile.country_id;
            this.kycForm.street = data.data.personal_profile.address;
            this.kycForm.zipcode = data.data.personal_profile.post_code;
            this.kycForm.mobilephone = data.data.personal_profile.phone;
          } else {
            this.kycForm.firstname = data.data.corporate_profile.name;
            this.kycForm.lastname = data.data.corporate_profile.last_name;
            this.kycForm.birthday = new Date(data.data.corporate_profile.birthday);
            this.kycForm.birthplace = data.data.corporate_profile.birthplace;
            this.kycForm.gender = data.data.corporate_profile.gender ? data.data.corporate_profile.gender.toLowerCase() : null;
            this.kycForm.city = data.data.corporate_profile.city;
            this.kycForm.country = data.data.corporate_profile.country_id;
            this.kycForm.nationality = data.data.corporate_profile.country_id;
            this.kycForm.street = data.data.corporate_profile.address;
            this.kycForm.zipcode = data.data.corporate_profile.post_code;
            this.kycForm.mobilephone = data.data.corporate_profile.phone;
          }
        });
      } catch(error) {
        console.log(error);
      }
    },
    async logout() {
      await window.localStorage.setItem('accessToken', '');
      await this.$store.dispatch('setLoggedIn', false);
      await this.$store.dispatch('setAccessToken', null);
      this.$router.push('/login');
    },
    async getBankInfo() {
      this.bankInfoLoading = true;
      try {
        const bankInfoRes = await fetch(`${config.API}/api/v1/get-financial-data`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        ).then((res) => res.json());
        // this.bankForm.bank_name = bankInfoRes.data.bank_name;
        this.bankForm.iban = bankInfoRes.data.iban;
        this.bankForm.receiving_address = bankInfoRes.data.receiving_address;
        this.bankInfoLoading = false;
        setTimeout(() => {
          this.bankForm.bic_code = bankInfoRes.data.bic_code;
        }, 0);
      } catch (error) {
        console.log(error);
        this.bankInfoLoading = false;
      }
    },
    selectTab(tab) {
      this.isActive = tab;
    },
    getUserInfo() {
      this.kycLoading = true;
      fetch(`${config.API}/api/v1/get-user-info`, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('yooooooo', data);
          if (
            data?.data?.user?.status === 4 ||
            data?.data?.user?.status === 3
          ) {
            this.$store.dispatch("setCountryMistamtch", true);
            this.$router.push({
              name: "KYC Error",
              params: { status: data.data.user.status },
            });
          }
          this.user = data?.data?.user;
          this.kycForm.email = data?.data?.user?.email;
          this.kycLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.kycLoading = false;
        });
    },
    getNationalities() {
      fetch(`${config.API}/api/v1/nationalities`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.nationalities = data.data.countries;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCountries() {
      fetch(`${config.API}/api/v1/countries`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.countries = data.data.countries;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePassword() {
      let validationResult = 0;
      if (!this.password || this.password.length < 8) {
        this.passwordShort = true;
        this.invalidPassword = false;
        validationResult++;
      } else if (!this.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+_-])[A-Za-z\d@$!%*?&+_-]{8,20}$/)) {
        this.invalidPassword = true;
        this.passwordShort = false;
        validationResult++;
      } else {
        this.invalidPassword = false;
        this.passwordShort = false;
      }
      console.log(this.password_confirmation === this.password, this.password, this.password_confirmation);
      if (this.password_confirmation !== this.password) {
        this.passwordConfirmNoMatch = true;
        validationResult++;
      } else {
        this.passwordConfirmNoMatch = false;
      }
      if (validationResult) {
        return;
      }
      fetch(`${config.API}/api/v1/change-password`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          current_password: this.current_password,
          password: this.password,
          password_confirmation: this.password_confirmation,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors && data.errors.length) {
            this.submitted = false;
            this.handleErrors(data.errors);
          } else {
            this.password = null;
            this.password_confirmation = null;
            this.current_password = null;
            this.$toast.success("Password succesfully changed.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async updateBankInfo() {
      this.bankValidationCalled = true;
      if (
        !this.bankForm.iban ||
        !this.bankForm.bic_code
        // !this.bankForm.receiving_address
      ) {
        return;
      }

      let validation = 0;

      if (!iban.isValid(this.bankForm.iban)) {
        this.invalidIban = true;
        validation++;
      } else {
        this.invalidIban = false;
      }

      // try {
      //   ethers.utils.getAddress(this.bankForm.receiving_address);
      //   this.invalidEthAddress = false;
      // } catch(error) {
      //   validation++;
      //   this.invalidEthAddress = true;
      // }

      if (validation > 0 || this.invalidBic) {
        return;
      }

      this.bankInfoLoading = true;
      let reqBody = { ...this.bankForm };
      try {
        const bankRes = await fetch(
          `${config.API}/api/v1/update-financial-data`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(reqBody),
          }
        ).then((res) => res.json());
        this.$toast.success("Bank details updated");
        this.bankInfoLoading = false;
      } catch (error) {
        console.log(error);
        this.bankInfoLoading = false;
      }
    },
    handleErrors(errors) {
      var errorStr = "";
      if (errors.length) {
        errors.forEach((value) => {
          errorStr = errorStr + value + "<br>";
        });
      }
      this.$toast.error(errorStr);
    },
    closeModal() {
      this.showLoginModal = false;
      this.showDepositModal = false;
    },
    async submitKyc() {
      this.validationCalled = true;
      let reqBody = { ...this.kycForm };
      let formInvalid = false;
      Object.keys(reqBody).forEach((item) => {
        if (!reqBody[item]) {
          formInvalid = true;
        }
      });
      if (formInvalid) {
        return;
      }
      let date = new Date(this.kycForm.birthday);
      const year = date.getFullYear();
      console.log((date.getMonth() + 1).toString().length);
      const month =
        (date.getMonth() + 1).toString().length < 2
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1;
      const day =
        date.getDate().toString().length < 2
          ? `0${date.getDate()}`
          : date.getDate();
      console.log(`${year}-${month}-${day}`);
      reqBody.birthday = `${year}-${month}-${day}`;
      try {
        this.kycLoading = true;
        const kycRes = await fetch(`${config.API}/api/v1/create-kyc-indent`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(reqBody),
        }).then((res) => res.json());
        if (kycRes.errors) {
          this.kycErrors = kycRes.errors;
        } else {
          this.kycErrors = null;
        }
        this.iframeID = kycRes.data.ident.indent_id;
        this.kycLoading = false;
      } catch (error) {
        console.log(error);
        this.kycLoading = false;
      }
    },
  },
  mounted() {
    console.log(this.$store.state.hasMinDeposit);
    this.hasDeposits = !!this.$store.state.hasMinDeposit;
    this.hasCompletedKyc = this.$store.state.userData?.completed_kyc;
    this.loggedIn = this.$store.state.loggedIn;
    this.$store.watch((state) => {
      this.accessToken = state.accessToken;
      this.hasDeposits = !!state.hasMinDeposit;
      this.hasCompletedKyc = state.userData?.completed_kyc;
      this.loggedIn = state.loggedIn;
      if (state.accessToken) {
        this.getBankInfo();
        this.getCountries();
        this.getNationalities();
        this.getUserInfo();
        this.getUserProfile();
      }
    });
  },
});
</script>